import { useStaticQuery, graphql } from 'gatsby'

export const useFrontItems = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulCatalogFront {
        data: nodes {
          id
          color {
            title
            image {
              file {
                url
              }
            }
          }
          description
          finish
          handleTypes {
            title
            imageMain {
              file {
                url
              }
            }
          }
          imageMain {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 600
                  formats: [WEBP]
                )
              }
            }
          }
          imageMainClose {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 600
                  formats: [WEBP]
                )
              }
            }
          }
          optionKey
          priceRange
          texture
        }
      }
    }
  `)

  return content
}
