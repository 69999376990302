import React, { useCallback, useEffect } from 'react'

import { uniqBy } from 'lodash'

import SEO from 'components/shared/seo'
import { useFrontItems } from 'content-queries/cx/front-items'
import fireEvent from 'context/tracking/events'
import CatalogProducts, { ProductItem } from 'mx/cx/category/catalog-products'
import FilterLayout from 'mx/cx/filter-layout'

type Filters = {
  handleTypes: string[]
  color: string[]
  texture: string[]
  finish: string[]
  priceRange: string[]
}

const initialFilters: Record<keyof Filters, any[]> = {
  handleTypes: [],
  color: [],
  texture: [],
  finish: [],
  priceRange: [],
}

const FrontsPage = ({ path }: { path?: string }) => {
  const { data: content } = useFrontItems()

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM Catalog - Fronts',
    })
  }, [])

  const applyFilters = useCallback(
    (activeFilters: Filters | null) => (item: ProductItem) => {
      const flags = []
      if (activeFilters?.handleTypes)
        flags.push(
          item.handleTypes?.find((h) =>
            activeFilters.handleTypes.includes(String(h.title)),
          ),
        )
      if (activeFilters?.color)
        flags.push(activeFilters.color.includes(String(item.color?.title)))
      if (activeFilters?.texture)
        flags.push(activeFilters.texture.includes(String(item.texture)))
      if (activeFilters?.finish)
        flags.push(activeFilters.finish.includes(String(item.finish)))

      if (activeFilters?.priceRange)
        flags.push(activeFilters.priceRange.includes(String(item.priceRange)))

      return flags.every(Boolean)
    },
    [],
  )

  const buildFilterData = useCallback((sortedItems: ProductItem[]): Filters => {
    const filterData = { ...initialFilters }

    sortedItems.forEach((item) => {
      item.handleTypes?.map((ht) => {
        const existingHandleType = filterData.handleTypes.find(
          (_ht) => _ht.title === ht.title,
        )
        if (!existingHandleType) filterData.handleTypes.push(ht)
      })
      filterData.color.push(item.color)
      filterData.finish.push(item.finish)
      filterData.texture.push(item.texture)
      filterData.priceRange.push(item.priceRange)
    })

    return {
      handleTypes: filterData.handleTypes.sort(),
      color: uniqBy(filterData.color, (c) => c.title),
      texture: [...new Set(filterData.texture)].sort().filter(Boolean),
      finish: [...new Set(filterData.finish)].sort().filter(Boolean),
      priceRange: [...new Set(filterData.priceRange)].sort().filter(Boolean),
    }
  }, [])

  return (
    <FilterLayout<ProductItem, Filters>
      path={path}
      title="Fronts"
      applyFilters={applyFilters}
      buildFilterData={buildFilterData}
      content={content}
      initialFilters={initialFilters}
    >
      {({ currentItem, filteredItems, onChangeFilter }) => (
        <>
          <CatalogProducts
            product="fronts"
            itemFromHash={currentItem}
            items={filteredItems}
            onChangeFilter={onChangeFilter}
          />
        </>
      )}
    </FilterLayout>
  )
}

export default FrontsPage

export const Head = () => (
  <SEO
    description="Discover FORM Kitchens European cabinet fronts. From flat-panel to slabs and shaker styles."
    title="Catalog - Fronts"
  />
)
